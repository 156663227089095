import React from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import clsx from "clsx";
import { navigate } from "gatsby";
import moment from "moment";

import { TotalPackAppointment } from "~/utils/interfaces/Appointment";

moment.locale("es");

interface TotalPackDashboardGridProps {
  appointments: TotalPackAppointment[];
  totalRows: number;
  currentPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
}

const TotalPackDashboardGrid = ({
  appointments,
  totalRows,
  currentPage,
  handleChangePage,
}: TotalPackDashboardGridProps) => {
  const displayTableRows = (): Array<JSX.Element> => {
    return appointments.map((appointment: TotalPackAppointment) => {
      const patientRut = appointment.patients[0].document_number;
      return (
        <TableRow key={appointment.id}>
          <TableCell
            onClick={async () => navigate(`/appointment/${appointment.id}/`)}
            className={clsx(
              "relative hover:bg-whitesmoke cursor-pointer flex flex-col",
              appointment.time_conflict && "bg-red-500",
            )}
          >
            <p className="font-bold">{appointment.patient?.fullname}</p>
            <span className="font-bold my-1">
              {patientRut} <ArrowRightAltIcon fontSize="small" />
              <span className="font-normal">{moment(appointment.local_begin_date).format("LLL")}</span>
            </span>
            <br />
            {appointment.target_address}
            <br />
            {appointment.service_names}
            {appointment.time_conflict && (
              <span className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1">
                Conflicto de Horario
              </span>
            )}
          </TableCell>

          <TableCell>
            {appointment.totalpack_request_status === "connected-successfully" && "Conectado exitosamente"}
            {appointment.totalpack_request_status === "without-connection" && "Sin conexión"}
            {appointment.totalpack_request_status === "partially-connected" && "Conexión parcial"}
            {appointment.totalpack_request_status === "closed-appointment" && "Cita cerrada"}
          </TableCell>
          <TableCell>{!!appointment.totalpack_request_message && appointment.totalpack_request_message}</TableCell>
        </TableRow>
      );
    });
  };

  return (
    <div className="mx-12 mb-12">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Appointment</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Mensaje</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{displayTableRows()}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={totalRows}
                rowsPerPage={20}
                rowsPerPageOptions={[20]}
                page={currentPage}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TotalPackDashboardGrid;
